import React, {
  useContext,
  useState,
  lazy,
  Suspense,
  createContext,
} from "react";
import { FiArrowLeft } from "react-icons/fi";
import {
  Search,
  Expanded,
  BookableDetails,
  BookConfirmed,
  Tabs,
} from "../../../components/";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { useTranslate } from "../../../Hooks";
import "./SearchResult.css";
const ListView = lazy(() => import("../../Views/Mobile/ListView/ListView"));
const MapView = lazy(() => import("../../Views/Mobile/MapView/MapView"));
export const expandContext = createContext();
const SearchResult = () => {
  const { setterForRender } = useContext(confirmationContext);
  const [expand, setExpand] = useState(false);
  const [tab, setTab] = useState("map");
  const translate = useTranslate();
  const tabRender = () => {
    switch (tab) {
      case "map":
        return <MapView />;
      case "list":
        return <ListView expand={setExpand} />;
      default:
        throw new Error("404 NOT FOUND!!");
    }
  };
  const collapsedContent = () => {
    return (
      <div className="btns">
        <button
          className="return"
          onClick={() => {
            setExpand(false);
            setterForRender(<BookConfirmed title={"Booking confirmed"} />);
          }}
        >
          {translate("Book")}
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="edit-search">
        <button
          className="fl-row-center"
          onClick={() => setterForRender(<Search />)}
        >
          <FiArrowLeft /> {translate("Edit search criteria")}
        </button>
      </div>
      <div className={`parent ${expand && "m25"}`}>
        <Tabs changeTab={setTab} tab={tab} />
        <expandContext.Provider value={{ expand: setExpand }}>
          <Suspense fallback={<div>Loading</div>}>{tabRender()}</Suspense>
          {expand && (
            <Expanded close={setExpand} collapsedContent={collapsedContent()}>
              <BookableDetails
                close={setExpand}
                setterForRender={setterForRender}
              />
            </Expanded>
          )}
        </expandContext.Provider>
      </div>
    </>
  );
};

export default SearchResult;
