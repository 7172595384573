import React, { useContext } from "react";
import styled from "styled-components";
import { expandContext } from "../../../Confirmation/SearchResult/SearchResult";
import { useCreateBooking } from "../../../../Hooks";

const changePosition = (stringInPerecent , level) => {
  const splittedString = stringInPerecent.split("%");
  const Percentegae = Number(splittedString[0]) - level
  return `${Percentegae}%`
}
console.log(screen.availWidth);
const BookableCircle = styled.div`
  position: absolute;
  height: ${screen.availWidth > 768 ? "15px" : "7px"};
  width: ${screen.availWidth > 768 ? "15px" : "7px"};
  top: ${(props) => changePosition(props.div_top,1)};
  left: ${(props) => changePosition(props.div_left,0)};
  border-radius: 50%;
  cursor: pointer;
`;

const Bookable = ({ bookable }) => {
  const { expand } = useContext(expandContext);
  const { setSelected, selectedDot } = useCreateBooking();

  const handleCLick = () => {
    if (bookable.status === "available") {
      if (selectedDot === bookable.id) {
        setSelected();
      } else {
        setSelected(bookable.id, bookable);
      }
    }
    expand(true);
  };
  return (
    <BookableCircle
      div_width={bookable.div_width}
      div_height={bookable.div_height}
      div_top={bookable.div_top}
      div_left={bookable.div_left}
      className={
        selectedDot === bookable.id ? "selecteddot selecteddotmobile" : `${bookable.status}dot`
      }
      onClick={() => handleCLick()}
    />
  );
};

export default Bookable;
