import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Login from "./userService";
import jwtDecode from "jwt-decode";
const userLocal = localStorage.getItem("user");
const user = userLocal ? jwtDecode(userLocal) : {};
const initialState = {
  token: userLocal || "",
  user,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

/*Thunks*/
export const LoginAsync = createAsyncThunk("login/user", async (data) => {
  return await Login(data);
});
/*End Of Thunks*/
export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = {};
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = jwtDecode(action.payload.user);
        state.token = action.payload.user;
      })
      .addCase(LoginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      });
  },
});

export const selectUser = (state) => state.userSlice;
export default userSlice.reducer;
export const { reset } = userSlice.actions;
