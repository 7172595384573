import React, { useEffect, useContext } from "react";
import { FiUserPlus } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import {
  useAttendee,
  useTranslate,
  useCreateBooking,
  useSearch,
} from "../../../Hooks";
import { Attendes } from "../../../components";
import moment from "moment/moment";
import { NotificationManager } from "react-notifications";
// import { SocketContext } from "../../../Services/Socket";
const BookableDetails = () => {
  const { addAttende, addAttendeRender, setAddAttndee, hookValue, radiovalue } =
    useAttendee();
  const translate = useTranslate();
  // const { socket } = useContext(SocketContext);
  const {
    selectedDotInformation,
    bringMyTeam,
    addMember,
    createBooking,
    isLoading,
    isError,
    isSuccess,
  } = useCreateBooking();
  const { searchObject, getResults } = useSearch();

  const handleSaveAttendee = () => {
    if (hookValue === "") {
      NotificationManager.error(translate("Plaese choose member"));
    } else {
      if (radiovalue === "team") {
        addMember({
          name: hookValue.split("*")[1],
          type: "team",
          id: Number(hookValue.split("*")[0]),
        });
      } else if (radiovalue === "visitor") {
        addMember({
          name: hookValue,
          type: "guest",
          id: Math.floor(Math.random() * 500),
        });
      }
      setAddAttndee(false);
    }
  };
  const handleBook = async () => {
    await createBooking();
    await getResults(searchObject);
  };
  useEffect(() => {
    bringMyTeam();
  }, []);
  useEffect(() => {
    isError && NotificationManager.error(translate("Error"));
    isSuccess && NotificationManager.success(translate("Done"));
  }, [isError, isSuccess]);
  if (JSON.stringify(selectedDotInformation) == "{}") {
    return (
      <div>
        <div className="bookable-specification fl-col-start">
          <p>{translate("No bookable selected")}</p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="bookable-specification fl-col-start"></div>
      <div className="section">
        <p>{translate("name")}</p>
        <span>{selectedDotInformation.name}</span>
        <p>{translate("Booking date and time")}</p>
        <span>
          {moment(searchObject.from).format("YYYY/MM/DD")} -{" "}
          {moment(searchObject.from).format("hh:mm A")} -{" "}
          {moment(searchObject.to).format("hh:mm A")}
        </span>
      </div>
      <Attendes list={selectedDotInformation.attendes || []} />
      {addAttende && addAttendeRender()}
      <div className="btns fl-col-center">
        {!addAttende ? (
          <button
            className="cancel fl-row-center"
            onClick={() => setAddAttndee(true)}
          >
            <FiUserPlus />
            {translate("Add attendee")}
          </button>
        ) : (
          <button
            className="cancel fl-row-center"
            onClick={() => handleSaveAttendee()}
          >
            {translate("Save")}
          </button>
        )}
        <button className="return" onClick={() => handleBook()}>
          {isLoading ? <FaSpinner /> : translate("Book")}
        </button>
      </div>
    </div>
  );
};

export default BookableDetails;
