import { useState, useEffect } from "react";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Booking_API } from "../Helpers/constants";
import useLogin from "./LoginHook";
import  useTranslate  from "./Translation";
const useBookableFloorPlan = (detail) => {
  const [details, setDeatils] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useLogin();
  const translate = useTranslate();
  const getBookableFloorplan = async () => {
    try {
      const floorPlan = await axios.get(
        `${Booking_API}/getFloor?floorid=${detail.floorId}&bookableid=${detail.bookableId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return floorPlan.data;
    } catch (error) {
      NotificationManager.error(translate("Error getting this bookable"));
    }
  };
  useEffect(() => {
    getBookableFloorplan().then((pay) => {
      setDeatils(pay);
      setLoading(false);
    });
  }, []);

  return {
    BookableFloorplan: details,
    loading,
  };
};

export default useBookableFloorPlan;
