import React from "react";
import { useTranslate } from "../../Hooks";
const Choose = () => {
  const translate = useTranslate();
  return (
    <div className="choose">
      <a className="option" href={`/bookings?type=next`}>
        <span className="viewall">{translate("Next Bookings")}</span>
      </a>
      <a className="option" href={`/bookings?type=today`}>
        <span className="viewall">{translate("Today's Bookings")}</span>
      </a>
      <a className="option" href={`/bookings?type=past`}>
        <span className="viewall">{translate("Past Bookings")}</span>
      </a>
    </div>
  );
};

export default Choose;
