import React, { lazy, Suspense, useContext, useEffect } from "react";
import { BsHouseDoor, BsCalendar, BsPerson } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { useNavigate } from "react-router";
import { useTranslate } from "../../../Hooks";
import NotificationBoss from "./NotificationsComponents/NotificationBoss";
const Select = lazy(() => import("../Select/Select"));

const SlideDown = ({ close, mode, rect }) => {
  const translate = useTranslate();
  const { setter } = useContext(confirmationContext);
  const navigate = useNavigate();
  const LAPTOP = window.screen.availWidth > 768;
  const stylesInDesktop = {
    profile: { right: "0", left: "unset" },
    notificationHead: {
      left: LAPTOP ? `${rect.left + rect.width - 375}px` : "0",
      right: "unset",
    },
  };
  const logOut = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };
  const Modes = {
    burgerMenu: () => {
      return (
        <>
          <li
            onClick={() => {
              setter(false);
              close();
              navigate("/");
            }}
          >
            <BsHouseDoor /> {translate("Dashboard")}
          </li>
          <li
            onClick={() => {
              setter(false);
              close();
              navigate("/bookings");
            }}
          >
            <BsCalendar /> {translate("Bookings")}
          </li>
        </>
      );
    },
    notificationHead: () => {
      return (
        <>
          <h2>{translate("Notification")}</h2>
          <NotificationBoss />
        </>
      );
    },
    profile: () => {
      return (
        <>
          <Suspense fallback={<div>Loading</div>}>
            {window.screen.availWidth < 768 && <Select />}
          </Suspense>
          <li>
            <BsPerson /> {translate("Profile")}
          </li>
          <li onClick={() => logOut()}>
            <FiLogOut /> {translate("Log out")}
          </li>
        </>
      );
    },
  };
  return (
    <div
      className={
        mode === "notificationHead" ? "slideDown Screenheight" : "slideDown"
      }
      style={stylesInDesktop[mode]}
    >
      <button onClick={() => close()}>X {translate("close")}</button>
      <ul>{Modes[mode]() || ""}</ul>
    </div>
  );
};

export default SlideDown;
