import React, { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { useSearch } from "../../../Hooks";
const SearchRadio = ({ title, list, name, changeHandler, dataset }) => {
  const { searchObject } = useSearch();
  const handleChange = (e) => {
    changeHandler(dataset, e);
  };
  return (
    <div className="input">
      <label>{title}</label>
      <RadioGroup
        name={name}
        selectedValue={searchObject[dataset]}
        className="radio"
        onChange={handleChange}
      >
        {list.map((radio) => {
          return (
            <div className="one-radio" key={radio.id}>
              <Radio value={radio.value} id={radio.value} />
              <label htmlFor={radio.value}>{radio.label}</label>
            </div>
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default SearchRadio;
