import React from "react";
import { BiError, BiCheck } from "react-icons/bi";
import moment from "moment/moment";
import "moment-timezone";
import { useTranslate } from "../../../../../Hooks";
import { timeZone } from "../../../../../Helpers/constants";

const InvitationAction = ({ notification, decline }) => {
  const translate = useTranslate();
  return (
    <div
      className={`notify fl-col-center ${
        notification.read ? "read" : "unread"
      }`}
    >
      <div className="fl-row-start time">
        {moment(notification["created_at"]).tz(timeZone).format("MM-DD hh:mm A")}
      </div>
      <div className="wrapper fl-row-center">
        <div className="icon fl-row-center">
          {decline ? <BiError /> : <BiCheck />}
        </div>
        <p className="message">
          {notification["first_name"]} {notification["last_name"]}{" "}
          {decline ? translate("signed out") : translate("signed in")} {" "} {translate("your booking")}
        </p>
      </div>
    </div>
  );
};

export default InvitationAction;
