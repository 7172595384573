export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const plansBookables = {
  9: [
    {
      id: 1,
      div_width: "15px",
      div_height: "15px",
      div_top: "20.7711%",
      div_left: "60.0654%",
    },
    {
      id: 2,
      div_width: "15px",
      div_height: "15px",
      div_top: "13.5572%",
      div_left: "33.9216%",
    },
    {
      id: 3,
      div_width: "15px",
      div_height: "15px",
      div_top: "10.3234%",
      div_left: "15.7516%",
    },
    {
      id: 4,
      div_width: "15px",
      div_height: "15px",
      div_top: "44.6517%",
      div_left: "33.3987%",
    },
    {
      id: 5,
      div_width: "15px",
      div_height: "15px",
      div_top: "46.1443%",
      div_left: "19.281%",
    },
    {
      id: 6,
      div_width: "15px",
      div_height: "15px",
      div_top: "14.5522%",
      div_left: "89.6078%",
    },
  ],
};
