import axios from "axios";
import { ATTENDEE_API, Booking_API } from "../../Helpers/constants";
import { sendNotifications } from "../Notifications/notificationsServices";
import { socket } from "../Socket";
export const getTeam = async (data) => {
  try {
    const response = await axios.get(
      ATTENDEE_API + `/team/?departmentId=${data.department}&id=${data.id}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const createBookingRequest = async (data) => {
  try {
    const response = await axios.post(Booking_API, data, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
    const notification = {
      type: "invitation",
      booking_id: response.data.booking.id,
      sender_id: data.ownerUserId,
      token: data.token,
    };
    data.attendes.forEach((attendee) => {
      attendee.type === "team" &&
        sendNotifications({ ...notification, consignee_id: attendee.id });
    });
    console.log(socket);
    socket.emit("private-notifications", {
      ...notification,
      users: data.attendes,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};
