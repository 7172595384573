import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizations, getEquipments, getResults } from "./searchServices";
const misData = {
  bookable_type: {
    error: false,
    message: "please choose your desired bookable type",
  },
  location_id: {
    error: false,
    message: "please complete your location selection",
  },
  floor_number: {
    error: false,
    message: "please choose a floor number",
  },
  from: {
    error: false,
    message: "please choose valid date and time",
  },
  to: {
    error: false,
    message: "please choose valid date and time",
  },
};
const initialState = {
  organizations: [],
  equipments: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  floorplan: {},
  bookables: [],
  message: "",
  missingDate: { ...misData },
  searchObject: {
    equipments: [],
    bookable_type: "",
  },
};

/*Thunks*/
export const OrganizationAsync = createAsyncThunk(
  "search/organization",
  async (token) => {
    return await getOrganizations(token);
  }
);

export const equipmentsAsync = createAsyncThunk(
  "search/equipments",
  async (token) => {
    return await getEquipments(token);
  }
);

export const resultsAsync = createAsyncThunk(
  "search/results",
  async (token) => {
    return await getResults(token);
  }
);
/*End Of Thunks*/

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.organizations = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.floorplan = {};
      state.missingDate = { ...misData };
      state.bookables = [];
      state.searchObject = {
        equipments: [],
        bookable_type: "",
      };
    },
    resetEquipment : (state) => {
      state.equipments = [];
    },
    setSearch: (state, action) => {
      if (action.type === "searchSlice/setSearch") {
        state.searchObject = { ...action.payload.newObj };
      }
    },
    modifyMissingData: (state, action) => {
      let keyToModify = action.payload.key;
      for (let key in state.missingDate) {
        if (key == keyToModify) {
          state.missingDate[key].error = action.payload.value;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(OrganizationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.organizations = action.payload.organization;
      })
      .addCase(OrganizationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(OrganizationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(equipmentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.equipments = action.payload.equiepemnts;
      })
      .addCase(equipmentsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(equipmentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      })
      .addCase(resultsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.floorplan = action.payload.floorplan;
        state.bookables = action.payload.searchBookables;
      })
      .addCase(resultsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resultsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      });
  },
});

export const selectSearch = (state) => state.searchSlice;
export default searchSlice.reducer;
export const { reset, setSearch, modifyMissingData , resetEquipment} = searchSlice.actions;
