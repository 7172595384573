import React from "react";
import "./BreadCrumb.css";
import { useTranslate } from "../../Hooks";
const BreadCrumb = ({ nowPage, display }) => {
  const translate = useTranslate();
  return (
    <div className="breadcrumb">
      <a href="/" onClick={() => display(false)}>
        {translate("Dashboard")}
      </a>
      <span className="break">/</span> {nowPage}
    </div>
  );
};

export default BreadCrumb;
