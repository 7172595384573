import styled from "styled-components";

export const SpecificContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SpecificInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const SpecificInput = styled.input`
  padding: 15px;
  margin-top: unset !important;
  margin-bottom: unset !important;
`;

export const SpecificLabel = styled.label`
  width: 40px;
`;
