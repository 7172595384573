import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import DateComponent from "../../Date/Date";
import { FaSpinner } from "react-icons/fa";
import { BreadCrumb, Success, Deleted } from "../../../components";
import { useTranslate, useLogin, useBooking } from "../../../Hooks";
import { RadioGroup, Radio } from "react-radio-group";
import { SpecificContainer, SpecificInput } from "./SpecificTime";
import Notification from "../Notification/Notification";
import moment from "moment";
import { NotificationManager } from "react-notifications";
const Modify = ({ display, setRender, meeting, renderTime }) => {
  const translate = useTranslate();
  const { user } = useLogin();
  const { Update, check_availabilty } = useBooking(meeting.id);
  const [date, setDate] = useState(
    moment(meeting.start_datetime).format("YYYY-MM-DD")
  );
  const [timing, setTimimg] = useState("");
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [available, setAvailable] = useState(false);
  const [bookings, setBookings] = useState([]);
  const checkBookbaleStatusCondition = () => {
    if (from != "" && to != "") {
      check_availabilty(meeting.bookable_id, from, to).then((pay) => {
        setAvailable(pay.status);
        setBookings(pay.bookedBookings);
      });
    }
  };
  const handleTimingChange = (e) => {
    setTimimg(e);
  };
  const handleBookingTime = (e) => {
    const time = e.target.value.split(":");
    if (e.target.id === "from") {
      setFrom(new Date(new Date(date).setHours(time[0], time[1], 0, 0)));
    } else {
      setTo(new Date(new Date(date).setHours(time[0], time[1], 0, 0)));
    }
  };
  useEffect(() => {
    if (timing === "full") {
      setFrom(new Date(new Date(date).setHours(0, 0, 0, 0)));
      setTo(new Date(new Date(date).setHours(23, 59, 59, 999)));
    }
    if (from != "" && to != "" && timing != "full") {
      setFrom(new Date(new Date(date).setHours(from.getHours())));
      setTo(new Date(new Date(date).setHours(to.getHours())));
    }
  }, [timing, date]);
  useEffect(() => {
    checkBookbaleStatusCondition();
  }, [from, to]);
  const handleUpdate = async () => {
    if (!from || !to) {
      NotificationManager.error(translate("please choose time"));
      return;
    }
    if (from > to) {
      NotificationManager.error(translate("The time is invalid"));
      return;
    }
    setLoading(true);
    try {
      await Update({
        booking_id: meeting.id,
        start_datetime: from.toString(),
        end_datetime: to.toString(),
      });
      setLoading(false);
      setRender(
        <Success
          title={translate("Changes saved")}
          display={display}
          data={meeting}
        />
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="parent">
      <BreadCrumb
        nowPage={`${date} - ${meeting.name_of_booked_place}`}
        display={display}
      />
      <div className="date">
        <DateComponent date={new Date(meeting.start_datetime)} />
      </div>
      <div className="details">
        <p>
          {meeting.location_name} - {meeting.name_of_booked_place}
        </p>
        {renderTime()}
        <p>
          {user.first_name} {user.last_name}
        </p>
      </div>
      <form>
        <div className="input">
          <label htmlFor={"date"}>{translate("Date")}</label>
          <input
            id={"date"}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        {/* The timeming */}
        <div className="input">
          <label>{translate("Time")}</label>
          <RadioGroup
            name={"timing"}
            selectedValue={timing}
            className="radio"
            onChange={(e) => handleTimingChange(e)}
          >
            <div className="one-radio">
              <Radio
                value={"full"}
                id={"full"}
                disabled={date <= moment(new Date()).format("YYYY-MM-DD")}
              />
              <label htmlFor={"full"}>{translate("Full time")}</label>
            </div>
            <div className="one-radio">
              <Radio value={"time"} id={"time"} />
              <label htmlFor={"time"}>{translate("Specific time")}</label>
            </div>
          </RadioGroup>
        </div>
        {timing === "time" && (
          <SpecificContainer>
            <label htmlFor="from">From</label>
            <SpecificInput
              type={"time"}
              id="from"
              onChange={handleBookingTime}
            />
            <label htmlFor="to">To</label>
            <SpecificInput type={"time"} id="to" onChange={handleBookingTime} />
          </SpecificContainer>
        )}
      </form>
      {to != "" && from != "" && (
        <Notification
          available={available}
          bookingBooked={bookings}
        />
      )}
      <div className="btns fl-col-center">
        <button
          className="return"
          disabled={!available}
          onClick={() => handleUpdate()}
        >
          {loading ? <FaSpinner /> : translate("Save changes")}
        </button>
        <button className="cancel" onClick={() => display(false)}>
          {translate("cancel")}
        </button>
        <button
          className="delete"
          onClick={() =>
            setRender(
              <Deleted
                display={display}
                setRender={setRender}
                meeting={meeting}
              />
            )
          }
        >
          <BiTrash /> {translate("Delete Booking")}
        </button>
      </div>
    </div>
  );
};

export default Modify;
