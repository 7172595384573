import axios from "axios";
import { Booking_API } from "../../Helpers/constants";

export const GetUserBooking = async (data) => {
  try {
    const response = await axios.get(Booking_API + `/get/${data.id} `, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const GetBookingAttendess = async (booking_id, token) => {
  try {
    const response = await axios.get(
      Booking_API + `/attendees/${booking_id} `,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const DeleteBooking = async (booking_id, token) => {
  try {
    const response = await axios.delete(Booking_API, {
      data: {
        booking_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const UpdateBooking = async (data, token) => {
  try {
    const response = await axios.put(Booking_API, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const BookingsType = async (user_id, type, limit, offset, token) => {
  try {
    const response = await axios.get(Booking_API + `/type`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        user_id,
        type,
        limit,
        offset,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};
