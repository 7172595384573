import React, { useState, useRef, useContext, createContext } from "react";
import { Success } from "../../components";
import Date from "../Date/Date";
import {
  useOutside,
  useTranslate,
  useBooking,
  useLogin,
  useNotification,
} from "../../Hooks";
import More from "./More/More";
import "./Meeting.css";
import { confirmationContext } from "../../Hooks/ConfirmationHook";
import moment from "moment";
import "moment-timezone";
import { timeZone } from "../../Helpers/constants";
import { FaSpinner } from "react-icons/fa";
export const meetingContext = createContext();
const Meeting = ({ meeting, past }) => {
  const ref = useRef();
  const [checkIn, setCheckIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { attendeeObj, AttendAction } = useBooking(meeting.id);
  const { user, token } = useLogin();
  const [signed, setSigned] = useState(attendeeObj["signed_in"]);
  const { setter, setterForRender } = useContext(confirmationContext);
  const translate = useTranslate();
  const { sender } = useNotification();
  const timeInMinute = parseInt(
    moment
      .duration(
        moment(meeting.end_datetime).diff(moment(meeting.start_datetime))
      )
      .asMinutes()
  );
  const handleCheck = () => {
    setCheckIn(true);
  };
  const handleSignIn = async () => {
    setLoading(true);
    await AttendAction(user.id, meeting.id, !signed, token).then((pay) => {
      setSigned(pay.action);
      setLoading(false);
    });
  };
  const handleConfirmation = async () => {
    await handleSignIn();
    await sender(meeting, !signed);
    setter(true);
    setterForRender(
      <Success
        data={meeting}
        display={setter}
        title={
          signed
            ? translate("You are cheked out")
            : translate("You are cheked in")
        }
      />
    );
  };
  useOutside(ref, setCheckIn, "more");

  /*Small Components*/
  const renderTime = () => {
    return (
      <p>
        {moment(meeting.start_datetime).tz(timeZone).format("hh:mm A")} -{" "}
        {moment(meeting.end_datetime).tz(timeZone).format("hh:mm A")} -{" "}
        {moment
          .utc()
          .startOf("year")
          .add({ minutes: timeInMinute })
          .format(`HH[ ${translate("Hours")} and ]mm`)}
        m
      </p>
    );
  };

  return (
    <div
      className={checkIn ? "Meeting-container mx-150" : "Meeting-container"}
      onClick={() => handleCheck()}
      ref={ref}
    >
      <div className="Meeting-information">
        <div className="date-content">
          <Date date={meeting.start_datetime} />
        </div>
        <div className="information">
          <p>
            {meeting.location_name} (Floor No.{meeting.floor_number}) - {meeting.name_of_booked_place}{" "}
            {meeting.owner_user_id === user.id
              ? `(${translate("Owner")})`
              : `(${translate("Attendee")})`}
          </p>
          {renderTime()}
        </div>
        <meetingContext.Provider
          value={{
            meeting: meeting,
            renderTime: renderTime,
          }}
        >
          <More past={past} owner={meeting.owner_user_id} user={user.id} />
        </meetingContext.Provider>
      </div>
      <div className="checkin">
        <button onClick={() => handleConfirmation()} disabled={past}>
          {past ? (
            translate("ended")
          ) : loading ? (
            <FaSpinner />
          ) : signed ? (
            translate("Check out")
          ) : (
            translate("Check in")
          )}
        </button>
      </div>
    </div>
  );
};

export default Meeting;
