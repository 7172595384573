import React , {Suspense , lazy} from 'react'

const Inputs = {
    email : lazy(() => import("./Email")),
    password : lazy(() => import("./Password")),
    submit : lazy(() => import("./Submit"))
}

const Input = (props) => {
    const Component = Inputs[props.type]
  return (
    <Suspense fallback={<div>Loading</div>}>
        <Component {...props}/>
    </Suspense>
  )
}

export default Input