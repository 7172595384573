import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import DateComponent from "../../Date/Date";
import { useTranslate , useLogin} from "../../../Hooks";
const Success = ({ display, title , data}) => {
  const translate = useTranslate();
  const {user} = useLogin()
  return (
    <>
      <h3>
        <BiCheckCircle /> {title}
      </h3>
      <div className="date">
        <DateComponent date={data.start_datetime} />
      </div>
      <div className="details">
        <p>{data.location_name} - {data.name_of_booked_place}</p>
        <p>{user.first_name} {user.last_name}</p>
      </div>
      <div className="btns fl-col-center">
        <button className="return" onClick={() => display(false)}>
          {translate("Return to your scheduled booking")}
        </button>
      </div>
    </>
  );
};

export default Success;
