import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import "../Login/Login.css";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useTranslate } from "../../Hooks";
import { useForgotPassword } from "../../Hooks";
import { Helmet } from "react-helmet";

const Reset = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [SearchParams] = useSearchParams();
  const { verifiedUser, updateUser } = useForgotPassword(
    SearchParams.get("token")
  );
  const navigate = useNavigate();
  const translate = useTranslate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (confirm != password) {
      NotificationManager.error(
        translate("Confirmation does not equal password")
      );
    } else {
      setLoading(true);
      updateUser(password)
        .then((pay) => {
          localStorage.setItem("resetSuccess", pay.msg);
          navigate("/login");
        })
        .catch(() => {
          NotificationManager.error(translate("Unexpected Error occured"));
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!verifiedUser) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Reset your password in IMPERIAL booking module"
        />
        <title>IBooking - Reset password</title>
      </Helmet>
      <div className="wrapper">
        <div className="leftSide">
          <div className="logo">
            <img
              src="https://imperialit.co.uk/images/logo-trans-color-new.PNG"
              alt=""
            />
          </div>
          <div className="gray"></div>
        </div>
        <div className="rightSide">
          <p className="login-par">Reset password</p>
          <form id="reset" onSubmit={handleSubmit}>
            <input
              type="password"
              autoComplete="off"
              placeholder="Enter your new password"
              min={8}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              autoComplete="off"
              placeholder="confirm your password"
              min={8}
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
            />
            <input type="Submit" disabled={loading} value={"Reset"} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reset;
