import { useState, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import "./Checkbox.css";
import { useTranslate, useSearch } from "../../Hooks";
const Checkbox = ({ label, dataset, id }) => {
  const { searchObject, settingSearch } = useSearch();
  const translate = useTranslate();
  const [value, setValue] = useState(false);
  const handleClick = () => {
    setValue(!value);
  };
  useEffect(() => {
    if (value) {
      let newArr = [...searchObject[dataset]];
      newArr.push(id);
      settingSearch(dataset, [...newArr]);
    } else {
      let valueIndex = searchObject[dataset].indexOf(id);
      if (valueIndex != -1) {
        let newArr = [...searchObject[dataset]];
        newArr.splice(valueIndex, 1);
        settingSearch(dataset, [...newArr]);
      }
    }
  }, [value]);
  return (
    <div className="check-container" onClick={() => handleClick()}>
      <div
        className={value ? "check checked" : "check"}
        role="checkbox"
        aria-checked={value}
        aria-label="checkbox"
      >
        <div className={value ? "circle left-95" : "circle"}></div>
        {value && <FiCheck />}
      </div>
      <p> {translate(label)} </p>
    </div>
  );
};
export default Checkbox;
