import React, {
  useState,
  lazy,
  Suspense,
  useRef,
  useEffect,
  useContext,
} from "react";
import { BiMenu, BiBell } from "react-icons/bi";
import SlideDown from "./Slide/SlideDown";
import "./Header.css";
import { useTranslate, useLogin, useNotification } from "../../Hooks";
import { SocketContext } from "../../Services/Socket";
import notificationSound from "../../assets/sounds/mixkit-software-interface-start-2574.wav";
const Select = lazy(() => import("./Select/Select"));

const Header = () => {
  const audio = new Audio(notificationSound);
  const [sideDown, setSideDown] = useState({ show: false, button: "" });
  const [read, setRead] = useState(false);
  const { user } = useLogin();
  const [rect, setRect] = useState({});
  const notificationRef = useRef();
  const translate = useTranslate();
  const { socket } = useContext(SocketContext);
  const { getNotifications, readNotifications, UnRead } = useNotification();
  const handleOpen = (bt) => {
    if (bt === "notificationHead") {
      setRect(notificationRef.current.getBoundingClientRect());
    }
    setSideDown({ show: true, button: bt });
  };
  const handleClose = () => {
    setSideDown({ show: false, button: "" });
  };
  useEffect(() => {
    getNotifications();
    socket.on("my-notify", (_) => {
      getNotifications();
      audio.play();
    });
  }, []);
  return (
    <header>
      {window.screen.availWidth < 768 && (
        <div
          className={
            sideDown.button === "burgerMenu"
              ? "burgerMenu active"
              : "burgerMenu"
          }
          onClick={() => handleOpen("burgerMenu")}
        >
          <BiMenu />
          <span>{translate("Menu")}</span>
        </div>
      )}
      <div className="logo">
        <img
          src="https://imperialit.co.uk/images/logo-trans-color-new.PNG"
          alt=""
        />
      </div>
      <Suspense fallback={<div>Loading</div>}>
        {window.screen.availWidth > 767 && <Select />}
      </Suspense>
      <div className="info">
        <div
          className={
            sideDown.button === "notificationHead"
              ? "notificationHead active"
              : "notificationHead"
          }
          ref={notificationRef}
          onClick={() => {
            handleOpen("notificationHead");
            setRead(true);
            readNotifications();
          }}
        >
          {UnRead.length && !read ? (
            <span className="notificationNumber">{UnRead.length}</span>
          ) : (
            ""
          )}
          <BiBell />
        </div>
        <div
          className={
            sideDown.button === "profile" ? "profile active" : "profile"
          }
          onClick={() => handleOpen("profile")}
        >
          <p>{`${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`}</p>
        </div>
      </div>
      {sideDown.show && (
        <SlideDown close={handleClose} mode={sideDown.button} rect={rect} />
      )}
    </header>
  );
};

export default Header;
