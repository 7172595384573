import {
  selectCreateBooking,
  setSelectedDot,
  setInformation,
  GetTeam,
  setAttendees,
  boookingCreation,
  resetCreateRequest,
  reset,
} from "../Services/Booking/createBooking";
import { useSelector, useDispatch } from "react-redux";
import useLogin from "./LoginHook";
import useSearch from "./SearchHook";
import { NotificationManager } from "react-notifications";
import useTranslate from "./Translation";
const useCreateBooking = () => {
  const { token, user } = useLogin();
  const { floorplan, searchObject } = useSearch();
  const {
    selectedDot,
    selectedDotInformation,
    team,
    isError,
    isLoading,
    isSuccess,
  } = useSelector(selectCreateBooking);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const setBookableInformation = (bookable) => {
    dispatch(setInformation({ bookable }));
  };
  const setSelected = (id = -1, bookable = {}) => {
    dispatch(setSelectedDot({ id: id }));
    setBookableInformation(bookable);
  };
  const bringMyTeam = () => {
    dispatch(GetTeam({ token, id: user.id, department: user.department_id }));
  };
  const addMember = (attendee) => {
    if (attendee.name.split(" ").length <= 1) {
      NotificationManager.warning(
        translate("Please add members in rigth format (first name - last name)")
      );
      return;
    }
    if (selectedDotInformation["attendes"]) {
      const member = selectedDotInformation["attendes"].filter(
        (m) => (m.id === attendee.id) & (m.type === "team")
      );
      member.length
        ? NotificationManager.warning(translate("This member is added before"))
        : dispatch(setAttendees({ attendee }));
    } else {
      dispatch(setAttendees({ attendee }));
    }
  };
  const resetSelected = () => {
    dispatch(reset());
  };

  const createBooking = () => {
    return new Promise(async (reslove, reject) => {
      dispatch(resetCreateRequest());
      const booking = {
        bookableId: selectedDot,
        ownerUserId: user.id,
        floorplanId: floorplan.id,
        startDateTime: new Date(searchObject.from).toISOString(),
        endDateTime: new Date(searchObject.to).toISOString(),
        notes: "",
        attendes: [...(selectedDotInformation["attendes"] || [])],
        equipments: searchObject["equipments"],
      };
      dispatch(boookingCreation({ ...booking, token }));
      resetSelected();
      reslove();
      reject();
    });
  };
  return {
    setSelected,
    selectedDot,
    selectedDotInformation,
    bringMyTeam,
    team,
    addMember,
    createBooking,
    isError,
    isLoading,
    isSuccess,
    resetSelected,
  };
};
export default useCreateBooking;
