import React, { useState, useRef, useContext } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { useOutside, useTranslate } from "../../../Hooks";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { Details, Modify, Deleted } from "../../../components";
import { meetingContext } from "../Meeting";
const More = ({ past, owner, user }) => {
  const [show, setShow] = useState(false);
  const { setter, setterForRender } = useContext(confirmationContext);
  const { meeting, renderTime } = useContext(meetingContext);
  const ref = useRef();
  const translate = useTranslate();

  useOutside(ref, setShow);
  const showConfirmation = () => {
    setter(true);
  };
  const handleViewDetails = () => {
    setterForRender(
      <Details display={setter} meeting={meeting} renderTime={renderTime} />
    );
    showConfirmation();
  };
  const handleModifyMeeting = () => {
    setterForRender(
      <Modify
        display={setter}
        setRender={setterForRender}
        meeting={meeting}
        renderTime={renderTime}
      />
    );
    showConfirmation();
  };
  const handleDeleteBooking = () => {
    setterForRender(
      <Deleted display={setter} setRender={setterForRender} meeting={meeting} />
    );
    showConfirmation();
  };
  return (
    <div className="more" ref={ref}>
      <button onClick={() => setShow(!show)}>
        <BiDotsVertical />
      </button>
      {show && (
        <ul>
          <li onClick={() => handleViewDetails()}>
            {translate("View Details")}
          </li>
          {owner == user && (
            <>
              {!past && (
                <li onClick={() => handleModifyMeeting()}>
                  {translate("Modify Booking")}
                </li>
              )}
              <li onClick={() => handleDeleteBooking()}>
                {translate("Delete Booking")}
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};

export default More;
