import React from "react";
import { Meeting } from "../components";
import { useTranslate } from "../Hooks";
import { Link } from "react-router-dom";
const MeetingWrapper = ({ title, List, type }) => {
  const translate = useTranslate();
  const ReturnMeetings = () => {
    if (List.length > 3) {
      return List.slice(0, 3);
    }
    return List;
  };
  const renderMeetings = ReturnMeetings();
  return (
    <div className="todays dashboard-div">
      <h3>{title}</h3>
      {renderMeetings.length ? (
        renderMeetings.map((meeting) => {
          return (
            <Meeting
              key={meeting.id}
              meeting={meeting}
              past={type === "past"}
            />
          );
        })
      ) : (
        <p>{translate(`No meetings for ${type}! enjoy`)}</p>
      )}
      {List.length > 3 && (
        <Link to={`/bookings?type=${type}`} className="viewall">
          {translate("View all your bookings")}
        </Link>
      )}
    </div>
  );
};

export default MeetingWrapper;
