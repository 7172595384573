import axios from "axios";
import { RESET_API } from "../Helpers/constants";
import jwtDecode from "jwt-decode";
import bcrypt from "bcryptjs";
const useForgotPassword = (token = "") => {
  const checkEmail = async (email) => {
    try {
      const res = await axios.post(RESET_API, { email });
      return res.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  };
  const verifyToken = (token) => {
    if (token) {
      const exp = jwtDecode(token).exp * 1000;
      const date = new Date();
      const isExpired = exp < date.getTime();
      if (isExpired) {
        try {
          localStorage.setItem("resetExpire", true);
        } catch (error) {}
      }
      return isExpired;
    }
    return true;
  };
  const verifiedUser = verifyToken(token) ? null : jwtDecode(token);
  const updateUser = async (password) => {
    try {
      const salt = await bcrypt.genSalt(10);
      const hashedPassword = await bcrypt.hash(password, salt);
      const res = await axios.patch(RESET_API, {
        user_id: jwtDecode(token).id,
        hashedPassword,
      });
      return res.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  };
  return {
    checkEmail,
    verifiedUser,
    updateUser,
  };
};

export default useForgotPassword;
