import React from "react";
import "./Notification.css";
import { BiCheckCircle } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { useTranslate } from "../../../Hooks";
import moment from "moment";
import "moment-timezone";
import { timeZone } from "../../../Helpers/constants";
const Notification = ({ available, bookingBooked }) => {
  const translate = useTranslate();
  const controlComponent = () => {
    if (available) {
      return (
        <>
          <BiCheckCircle /> {translate("Desk available")}
        </>
      );
    }
    return (
      <>
        <FiAlertTriangle />{" "}
        {translate("Desk is not available on the date selected")}
      </>
    );
  };

  return (
    <div className={`notify ${available ? "available" : "not-available"}`}>
      <div className="fl-row-start">{controlComponent()}</div>
      {!available && (
        <div className="booked_times">
          {bookingBooked.map((book) => {
            return (
              <span className="time" disabled>
                {moment(book.start_datetime).tz(timeZone).format("hh:mm A")} -{" "}
                {moment(book.end_datetime).tz(timeZone).format("hh:mm A")}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Notification;
