import axios from "axios";
import { NOTIFICATIONS_API } from "../../Helpers/constants";

export const sendNotifications = async ({
  type,
  consignee_id,
  sender_id,
  booking_id,
  token,
}) => {
  try {
    const request = await axios.post(
      `${NOTIFICATIONS_API}/send`,
      {
        type,
        consignee_id,
        sender_id,
        booking_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const getNotifications = async ({ user_id, token }) => {
  try {
    const request = await axios.get(
      `${NOTIFICATIONS_API}/get?user_id=${user_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const updateToRead = async (user_id, token) => {
  try {
    const request = await axios.patch(
      `${NOTIFICATIONS_API}/read?user_id=${user_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const updateToAction = async ({ user_id, notification_id, token }) => {
  try {
    const request = await axios.patch(
      `${NOTIFICATIONS_API}/action?user_id=${user_id}&notification_id=${notification_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};
