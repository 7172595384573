import React, { useState } from "react";
import { BreadCrumb, Success } from "../../../components";
import { BiTrash } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";
import { useTranslate, useBooking } from "../../../Hooks";
import moment from "moment";
const Deleted = ({ display, setRender, meeting }) => {
  const translate = useTranslate();
  const { Delete } = useBooking(meeting.id);
  const [loading, setLoading] = useState(false);

  const DeleteBooking = () => {
    setLoading(true);
    Delete()
      .then(() => {
        setLoading(false);
        setRender(
          <Success
            title={translate("Booking Deleted")}
            display={display}
            data={meeting}
          />
        );
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="parent">
      <BreadCrumb nowPage={translate("Delete Booking")} display={display} />
      <div className="deleteConfirmation fl-col-center">
        <p className="fl-row-center">
          <BiTrash /> {translate("Delete Booking")}
        </p>
        <span>
          {meeting.name_of_booked_place} -{" "}
          {moment(meeting.start_datetime).format("YYYY/MM/DD")}
        </span>
      </div>
      <p className="confirmationMessage">
        {translate("Are you sure you want to delete this booking ?")}
      </p>
      <div className="btns fl-col-center">
        <button className="deleteFill" onClick={() => DeleteBooking()}>
          {loading ? <FaSpinner /> : translate("Delete")}
        </button>
        <button className="cancel" onClick={() => display(false)}>
          {translate("cancel")}
        </button>
      </div>
    </div>
  );
};

export default Deleted;
