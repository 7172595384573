import React, { useRef, useEffect } from "react";
import Dot from "./Dot";
import Bookable from "./Bookable";
import OneBookable from "./OneBookable";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { useTranslate, useSearch } from "../../../../Hooks";
const MapView = (props) => {
  const { floorplan, bookables } = useSearch();
  const imageRef = useRef();
  const translate = useTranslate();

  return (
    <div className="mapview view">
      {!props.NoHeadLine && (
        <h4>{translate("Select on an item to book it")}</h4>
      )}
      {!props.NoActions && (
        <div className="guiding fl-row-center">
          <Dot className={"selecteddot"} label="Selected" />
          <Dot className={"availabledot"} label="Available" />
          <Dot className={"unavailabledot"} label="Unavailable" />
        </div>
      )}
      <div className="img-wrapper-in-search">
        <div className="floorplan-container">
          <div className="floorplan-content fl-row-center">
            <div className="p-rel">
              <img src={props.floor || floorplan.image_url} ref={imageRef} />
              {props.bookabelOnFloor ? (
                <OneBookable bookable={props.bookabelOnFloor} />
              ) : (
                bookables.map((bookable) => {
                  return <Bookable key={bookable.id} bookable={bookable} />;
                })
              )}
            </div>
          </div>
          {/* <div className="floorplan-actions">
            <div
              className="floorplan-btns fl-col-center"
              style={{ marginTop: "8px" }}
            >
              {!props.NoActions && (
                <>
                  <button>
                    <FiZoomIn />
                  </button>
                  <button>
                    <FiZoomOut />
                  </button>
                </>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MapView;
