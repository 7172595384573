import React from "react";
import { useTranslate } from "../../../../Hooks";
const Dot = ({ className, label }) => {
  const translate = useTranslate();
  return (
    <div className="dot-container fl-row-center">
      <span className={`dot ${className}`}></span>
      <span className="label">{translate(label)}</span>
    </div>
  );
};

export default Dot;
