import { SEARCH_API } from "../../Helpers/constants";
import axios from "axios";

export const getOrganizations = async (token) => {
  try {
    const response = await axios.get(SEARCH_API + `/organizations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const getEquipments = async (token) => {
  try {
    const response = await axios.get(SEARCH_API + `/equipments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const getBuildings = async (data) => {
  try {
    const response = await axios.get(SEARCH_API + `/buildings/${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const getResults = async (data) => {
  try {
    const response = await axios.post(SEARCH_API, data, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

