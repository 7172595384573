import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../Services/user/userSlice";
import bookingReducer from "../Services/Booking/Bookingslice";
import searchReducer from "../Services/Search/searchSlice";
import createBookingReucer from "../Services/Booking/createBooking";
import notificationsReducer from "../Services/Notifications/notificationsSlice";
export const store = configureStore({
  reducer: {
    userSlice: userReducer,
    bookingSlice: bookingReducer,
    searchSlice: searchReducer,
    createBookingSlice: createBookingReucer,
    notificationsSlice: notificationsReducer,
  },
});
