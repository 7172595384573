import React, { useContext } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";
import DateComponent from "../../Date/Date";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { useAttendee , useTranslate } from "../../../Hooks";
import { Attendes, Search } from "../../../components";
import { useNavigate } from "react-router";
const BookConfirmed = ({ title }) => {
  const { setterForRender, setter } = useContext(confirmationContext);
  const { addAttende, addAttendeRender, setAddAttndee } = useAttendee();
  const translate = useTranslate();
  const navigate = useNavigate();
  return (
    <>
      <h3>
        <BiCheckCircle /> {title}
      </h3>
      <div className="date">
        <DateComponent date={new Date()} />
      </div>
      <div className="details">
        <p>London - Desk 12</p>
        <p>0:15am - 11:15am</p>
      </div>
      <Attendes />
      {addAttende && addAttendeRender()}
      <div className="btns fl-col-center">
        {!addAttende ? (
          <button
            className="cancel fl-row-center"
            onClick={() => setAddAttndee(true)}
          >
            <FiUserPlus />
            {translate("Add attendee")}
          </button>
        ) : (
          <button
            className="cancel fl-row-center"
            onClick={() => setAddAttndee(false)}
          >
            {translate("Save")}
          </button>
        )}
        <button className="return" onClick={() => setterForRender(<Search />)}>
          {translate("Create another booking")}
        </button>
        <button
          className="cancel"
          onClick={() => {
            setter(false);
            navigate("/");
          }}
        >
          {translate("view scheduled bookings")}
        </button>
      </div>
    </>
  );
};

export default BookConfirmed;
