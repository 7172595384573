import React from "react";
import { BiUserCircle } from "react-icons/bi";
import moment from "moment/moment";
import { useTranslate } from "../../../../../Hooks";

const BookingInvitation = ({ notification }) => {
  const translate = useTranslate();

  return (
    <div
      className={`notify fl-col-center ${
        notification.read ? "read" : "unread"
      }`}
    >
      <div className="fl-row-start time">
        {moment(notification["created_at"]).format("MM-DD hh:mm A")}
      </div>
      <div className="wrapper fl-row-center">
        <div className="icon fl-row-center">
          <BiUserCircle />
        </div>
        <p className="message">
          {notification["first_name"]} {notification["last_name"]} {translate("inviting you to attend booking starts on")} {" "}
          {moment(notification["start_datetime"]).format("YYYY-MM-DD")} at{" "}
          {moment(notification["start_datetime"]).format("HH:MM A")} to{" "}
          {moment(notification["end_datetime"]).format("HH:MM A")} in{" "}
          {notification["location_name"]}
        </p>
      </div>
    </div>
  );
};

export default BookingInvitation;
