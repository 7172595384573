// https://c9nvbd3v6j.execute-api.eu-west-1.amazonaws.com/dev
export const BASE =
  "https://c9nvbd3v6j.execute-api.eu-west-1.amazonaws.com/dev";
export const USER_API = `${BASE}/users`;
export const Booking_API = `${BASE}/book`;
export const SEARCH_API = `${BASE}/search`;
export const ATTENDEE_API = `${BASE}/attendee`;
export const NOTIFICATIONS_API = `${BASE}/notifications`;
export const RESET_API = `${BASE}/reset`;
export const LIMIT = 5;
export const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MAXDATE = () => {
  let dtToday = new Date();
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  let maxDate = year + "-" + month + "-" + day;
  return maxDate;
};
