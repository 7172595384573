import { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import useTranslate from "./Translation";
import useCreateBooking from "./createBookingHook";

const useAttendee = () => {
  const [addAttende, setAddAttndee] = useState(false);
  const translate = useTranslate();
  const { team } = useCreateBooking();
  const [radiovalue, setValue] = useState("team");
  const [hookValue, setHookValue] = useState("");
  useEffect(() => {
    setHookValue("");
  }, [radiovalue, addAttende]);
  const teamMember = () => {
    return (
      <div className="select-container" style={{ padding: "unset" }}>
        <label htmlFor={"team"}>{translate("Team member")}</label>
        <select
          name="team"
          id="team"
          onChange={(e) => setHookValue(e.target.value)}
        >
          <option value="">{translate("Choose member")}</option>
          {team.map((member) => {
            return (
              <option
                key={member.id}
                value={
                  member.id + "*" + member.first_name + " " + member.last_name
                }
              >
                {member.first_name} {member.last_name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  const guestMember = () => {
    return (
      <div className="select-container" style={{ padding: "unset" }}>
        <label htmlFor={"geust"}>{translate("Geust")}</label>
        <input
          type={"text"}
          id="geust"
          placeholder="Guest name"
          style={{ padding: "15px" }}
          onChange={(e) => setHookValue(e.target.value)}
          autoComplete={"off"}
        />
      </div>
    );
  };
  const RadioComponent = () => {
    const whoRadios = [
      {
        id: 2,
        value: "team",
        label: translate("Team member"),
      },
      {
        id: 3,
        value: "visitor",
        label: translate("Visitor"),
      },
    ];
    return (
      <div className="input">
        <RadioGroup
          name={"teammember"}
          selectedValue={radiovalue}
          className="radio"
          onChange={(e) => setValue(e)}
        >
          {whoRadios.map((radio) => {
            return (
              <div className="one-radio" key={radio.id}>
                <Radio value={radio.value} id={radio.value} />
                <label htmlFor={radio.value}>{radio.label}</label>
              </div>
            );
          })}
        </RadioGroup>
      </div>
    );
  };
  const addAttendeRender = () => {
    return (
      <>
        <div className="formSection">
          <label className="labelTitle">
            {translate("Who is the attendee")}
          </label>
          {RadioComponent()}
          {radiovalue === "team" ? teamMember() : guestMember()}
        </div>
      </>
    );
  };
  return {
    addAttende,
    setAddAttndee,
    addAttendeRender,
    radiovalue,
    hookValue,
    setHookValue,
  };
};

export default useAttendee;
