import React from "react";
import { NotchContainer, NotchColumn, NotchCircle } from "./Components";
import { monthNames } from "../../data";
import "./Date.css";
const DateC = ({ date }) => {
  const DateMaker = new Date(date);
  return (
    <div className="Date">
      <NotchContainer left>
        <NotchColumn />
        <NotchCircle />
      </NotchContainer>
      <NotchContainer right>
        <NotchColumn />
        <NotchCircle />
      </NotchContainer>
      <div className="month">
        <span>
          {monthNames[DateMaker.getMonth()].slice(0, 3).toUpperCase()}
        </span>
      </div>
      <div className="day">
        <div className="wrapper">
          <span>{DateMaker.getDate()}</span>
        </div>
      </div>
    </div>
  );
};

export default DateC;
