import React, { useState, useEffect, useContext } from "react";
import DateComponent from "../../Date/Date";
import { BreadCrumb } from "../../../components";
import { useTranslate, useLogin, useBooking } from "../../../Hooks";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import BookableFloorplan from "../BookableFloorplan/BookableFloorplan";
const Details = ({ display, meeting, renderTime }) => {
  const translate = useTranslate();
  const { user, token } = useLogin();
  const { GetBookingAttendess } = useBooking();
  const [loading, setLoading] = useState(true);
  const [attendees, setAttendes] = useState([]);
  const { setterForRender } = useContext(confirmationContext);
  useEffect(() => {
    GetBookingAttendess(meeting.id, token)
      .then((pay) => {
        setAttendes(pay.attendees);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <div className="parent">
      <BreadCrumb nowPage={translate("Meeting Details")} display={display} />
      <div className="date">
        <DateComponent date={new Date()} />
      </div>
      <div className="details">
        <p>
          {meeting.location_name} - {meeting.name_of_booked_place}
        </p>
        {renderTime()}
        <p>
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div className="btns">
        <button
          className="cancel"
          onClick={() => setterForRender(<BookableFloorplan floorId={meeting.floorplan_id} bookableId={meeting.bookable_id}/>)}
        >
          {translate("View desk on the floor plan")}
        </button>
      </div>
      <div className="attendes">
        <p>{translate("Who else is booked into the office")}</p>
        {loading ? (
          <p>Loading . . .</p>
        ) : (
          attendees.map((attendee) => {
            return (
              <span key={attendee.user_id || attendee.id}>
                {meeting.name_of_booked_place} -{" "}
                {attendee.name || `${attendee.firstName} ${attendee.lastName}`}
              </span>
            );
          })
        )}
      </div>
      <div className="btns">
        <button className="return" onClick={() => display(false)}>
          {translate("Return to your scheduled booking")}
        </button>
      </div>
    </div>
  );
};

export default Details;
