import React, { useState } from "react";
import Collapse from "../../assets/icons/sidebar-panel-collapse-icon.png";
import { BsHouseDoor, BsCalendar } from "react-icons/bs";
import { useTranslate } from "../../Hooks";
import { useNavigate } from "react-router";
import "./Aside.css";
const Aside = () => {
  const [expand, setExpand] = useState(false);
  const translate = useTranslate();
  const navigate = useNavigate()
  const handleClick = () => {
    setExpand(!expand);
  };
  const burgerMenu = () => {
    return (
      <>
        <li onClick={() => navigate("/")}>
          <BsHouseDoor /> {translate("Dashboard")}
        </li>
        <li onClick={() => navigate("/bookings")}>
          <BsCalendar /> {translate("Bookings")}
        </li>
      </>
    );
  };
  return (
    <aside className={expand ? "w-250" : ""}>
      <div className="collapse">
        <button
          onClick={() => handleClick()}
          className={expand ? "rotate" : ""}
        >
          <img
            src={Collapse}
            alt="Collapse button"
            className={expand ? "w-20p" : ""}
          />
          {expand && <span>{translate("Collapse")}</span>}
        </button>
      </div>
      {expand && (
        <div className="menu">
          <ul>{burgerMenu()}</ul>
        </div>
      )}
    </aside>
  );
};

export default Aside;
