import React, { useEffect } from "react";
import { Header, Aside, MeetingWrapper, Loading } from "../../components";
import { useConfirmation, useTranslate, useBooking } from "../../Hooks";
import { Helmet } from "react-helmet";
import "./Dashboard.css";
const Dashboard = () => {
  const {
    confirmationRender,
    setDisplay,
    setRenderData,
    confirmationContext,
    prevRender,
  } = useConfirmation();
  const { getBookings, isLoading, next, past, today } = useBooking();
  const translate = useTranslate();
  useEffect(() => {
    getBookings();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <confirmationContext.Provider
      value={{
        setter: setDisplay,
        setterForRender: setRenderData,
        prevRender: prevRender,
      }}
    >
      <Header />
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dashboard page to control your bookings"
          />
          <title>IBooking - Dashboard</title>
        </Helmet>
        {confirmationRender()}
        <div className="wrapper">
          <Aside />
          <div className="content">
            <h2>{translate("welcome")}</h2>
            <div className="actions dashboard-div">
              <h3>{translate("Booking Actions")}</h3>
              <a href={"/new"} className="newbooking">
                {translate("Create a new booking")}
              </a>
            </div>
            <div className="Meetings-widgets">
              <MeetingWrapper
                title={translate("Today meetings")}
                List={today}
                type="today"
              />
              <MeetingWrapper
                title={translate("Next meetings")}
                List={next}
                type="next"
              />
              <MeetingWrapper
                title={translate("Past meetings")}
                List={past}
                type="past"
              />
            </div>
          </div>
        </div>
      </main>
    </confirmationContext.Provider>
  );
};

export default Dashboard;
