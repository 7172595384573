import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import { emailRegEx } from "../../Helpers/constants";
import { NotificationManager } from "react-notifications";
import { useForgotPassword } from "../../Hooks";
import { Helmet } from "react-helmet";
const Forgot = () => {
  const [email, setEmail] = useState("");
  const [validEMail, setValidEMail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { checkEmail } = useForgotPassword();
  useEffect(() => {
    if (email.toLowerCase().match(emailRegEx) && email != "") {
      setValidEMail(true);
    } else {
      setValidEMail(false);
    }
  }, [email]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await checkEmail(email)
      .then((pay) => {
        NotificationManager.success(pay.msg);
        setLoading(false);
      })
      .catch((err) => {
        NotificationManager.error(err.message);
        setLoading(false);
      });
  };
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Reset your password in IMPERIAL booking module"
        />
        <title>IBooking - Forget password</title>
      </Helmet>
      <div className="wrapper">
        <div className="leftSide">
          <div className="logo">
            <img
              src="https://imperialit.co.uk/images/logo-trans-color-new.PNG"
              alt=""
            />
          </div>
          <div className="gray"></div>
        </div>
        <div className="rightSide">
          <p className="login-par">Forgot password ?</p>
          <form id="forgot" onSubmit={handleSubmit}>
            <input
              type="text"
              value={email}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="Submit"
              disabled={!validEMail}
              value={loading ? "Loading . . ." : "Send me email"}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
