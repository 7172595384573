import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "./Expanded.css";
import { useTranslate, useCreateBooking } from "../../Hooks";
const Expanded = ({ children, close, collapsedContent }) => {
  const [collapsed, setCollapsed] = useState(false);
  const translate = useTranslate();
  const { selectedDotInformation } = useCreateBooking();
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className={`expanded ${collapsed && "collapsed"}`}>
      <div className="head fl-row-center">
        <div className="title fl-row-start">{translate(selectedDotInformation.name)}</div>
        <div className="col-exap fl-row-end">
          <button className="fl-row-center" onClick={() => handleCollapse()}>
            {collapsed ? (
              <>
                <BiChevronUp /> {translate("expand")}
              </>
            ) : (
              <>
                <BiChevronDown /> {translate("Collapse")}
              </>
            )}
          </button>
        </div>
      </div>
      {collapsed ? (
        <>{collapsedContent}</>
      ) : (
        <>
          {children}
          <div className="close">
            <button className="fl-row-center" onClick={() => close()}>
              {translate("close")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Expanded;
