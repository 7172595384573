import React from "react";
import { useTranslate } from "../../../Hooks";
const SearchSelect = ({ title, className, List, handleChange , dataset}) => {
  const translate = useTranslate()
  return (
    <div className={className ? className : "input"}>
      <label htmlFor={title}>{title}</label>
      <select id={title} onChange={handleChange} data-key = {dataset}>
        <option value="">{translate("Choose Location")}</option>
        {List.map((organization) => {
          return (
            <option key={organization.id} value={organization.id}>
              {organization.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SearchSelect;
