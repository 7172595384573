import { useContext, useEffect, useState } from "react";
import { BreadCrumb, Checkbox, SearchResult } from "../../../components";
import SearchSelect from "../Inputs/SearchSelect";
import SearchRadio from "../Inputs/SearchRadio";
import RenderError from "./RenderError";
import { FiSearch } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { useTranslate, useSearch, useCreateBooking } from "../../../Hooks";
import { MAXDATE } from "../../../Helpers/constants";
import { NotificationManager } from "react-notifications";
import { RadioGroup, Radio } from "react-radio-group";
import {
  SpecificContainer,
  SpecificInput,
  SpecificInputWrapper,
  SpecificLabel,
} from "../Modify/SpecificTime";
import moment from "moment";
const Search = ({ desktop }) => {
  // ---------------------------------------------Begin Hook use-----------------------------------------------
  const {
    getOranizations,
    isError,
    isSuccess,
    isLoading,
    organizations,
    Buildings,
    settingSearch,
    equipments,
    getResults,
    searchObject,
    message,
    missingDate,
    resetEquipmentsHandler
  } = useSearch();
  const { resetSelected } = useCreateBooking();
  const { setterForRender, setter } = useContext(confirmationContext);
  const translate = useTranslate();
  const [Locations, setLocations] = useState([]);
  const [newLocations, setnewLocations] = useState({});
  const [Equipments, setEquipments] = useState([]);
  const [Floors, setFloors] = useState({ show: false, n: 0 });
  const [date, setDate] = useState("");
  const [timing, setTimimg] = useState("");
  const [halfDay, setHalfDay] = useState("");
  // ---------------------------------------------End Hook use-----------------------------------------------

  // ---------------------------------------------Begin Declare Functions-----------------------------------------------

  const handleTimingChange = (e) => {
    setTimimg(e);
  };
  const handleReset = () => {
    setLocations([]);
    setnewLocations([]);
    setFloors({ show: false, n: 0 });
    resetSelected();
    setTimimg("");
    setDate("");
    getOranizations();
  };
  const Submit = async () => {
    return await getResults(searchObject);
  };
  const searchElement = (response) => {
    return (
      <SearchSelect
        title={translate("Sub Location")}
        List={response.buildings}
        handleChange={onLocationChange}
        dataset={"location_id"}
      />
    );
  };
  const onLocationChange = async (e) => {
    e.target.disabled = true;
    if (e.target.value != "") {
      const response = await Buildings(e.target.value, {
        key: e.target.dataset.key,
        value: e.target.value,
      });
      setnewLocations(response);
    }
  };

  const onChange = (key, value) => {
    settingSearch(key, value);
  };
  const changeType = (key, value) => {
    if (value != "all") {
      setEquipments(equipments.filter((equip) => equip.type === value));
    } else {
      setEquipments(equipments);
    }
    onChange(key, value);
  };
  const handleBookingTime = (e) => {
    const time = e.target.value.split(":");
    if (e.target.id === "from") {
      onChange(
        "from",
        new Date(new Date(date).setHours(time[0], time[1], 0, 0)).toISOString()
      );
    } else {
      onChange(
        "to",
        new Date(new Date(date).setHours(time[0], time[1], 0, 0)).toISOString()
      );
    }
  };
  const handleHalfDay = (e) => {
    setHalfDay(e.target.value);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    const error = await Submit();
    if (!desktop && !error) {
      setterForRender(<SearchResult />);
    }
  };
  // ---------------------------------------------End Declare Functions-----------------------------------------------

  // ---------------------------------------------Begin Side effects-----------------------------------------------

  // intial effect
  useEffect(() => {
    getOranizations();
  }, []);

  // validation effect
  useEffect(() => {
    if (isError) {
      NotificationManager.error(message);
    }
    if (isSuccess) {
      //setEquipments(equipments.filter((equip) => equip.type === searchObject['bookable_type']));
    }
  }, [isLoading, isSuccess, equipments]);

  // Location effect
  useEffect(() => {
    if (newLocations?.type === "parent") {
      setLocations([...Locations, searchElement(newLocations)]);
    } else if (newLocations?.type === "child") {
      setFloors({ show: true, n: newLocations.floors });
    }
  }, [newLocations]);

  // time effect
  useEffect(() => {
    if (timing === "full") {
      onChange("time", {
        from: new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString(),
        to: new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString(),
      });
    } else if (timing === "half") {
      if (halfDay == "am") {
        onChange("time", {
          from: new Date(new Date(date).setHours(0, 0, 59, 999)).toISOString(),
          to: new Date(new Date(date).setHours(11, 59, 59, 999)).toISOString(),
        });
      } else {
        onChange("time", {
          from: new Date(new Date(date).setHours(12, 0, 0, 0)).toISOString(),
          to: new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString(),
        });
      }
    }
  }, [timing, date, halfDay]);
  // ---------------------------------------------End Side effects-----------------------------------------------

  // ---------------------------------------------Begin Small components-----------------------------------------------
  const renderLocations = () => {
    return (
      <>
        {isSuccess && (
          <>
            <SearchSelect
              title={translate("Location")}
              List={organizations}
              handleChange={onLocationChange}
              dataset={"location_id"}
            />
            {Locations.map((location, i) => {
              return <div key={i}>{location}</div>;
            })}
          </>
        )}
      </>
    );
  };
  const renderFloors = () => {
    return (
      <>
        {Floors["show"] && (
          <div className={"input"}>
            <label htmlFor="floors">{translate("Choose Floors ")}</label>
            <select
              id="floors"
              data-key={"floor_number"}
              onChange={(e) => onChange(e.target.dataset.key, e.target.value)}
            >
              <option value="">{translate("Choose Location")}</option>
              {Array.from({ length: Floors["n"] }, (_, i) => i + 1).map(
                (number, i) => {
                  return (
                    <option key={i} value={number}>
                      {number}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        )}
      </>
    );
  };

  // ---------------------------------------------End Small components-----------------------------------------------

  const BookingType = [
    {
      id: 1,
      value: "all",
      label: translate("All"),
    },
    {
      id: 2,
      value: "desk",
      label: translate("Desk"),
    },
    {
      id: 3,
      value: "meeting",
      label: translate("Meeting room"),
    },
  ];
  return (
    <div className="parent no-mx-height">
      <BreadCrumb
        nowPage={translate("Create a new booking")}
        display={setter}
      />
      <form onSubmit={handleClick}>
        <div
          className={`btns ${
            window.screen.availWidth > 1000 && window.screen.availWidth < 1300
              ? "fl-col-center"
              : "fl-row-center"
          }`}
        >
          <button
            className="cancel"
            style={{ padding: "17px" }}
            onClick={() => handleReset()}
            type="button"
          >
            {translate("Reset all")}
          </button>
          <button className="return fl-row-center">
            {isLoading ? <FaSpinner /> : <FiSearch />} {translate("Search")}
          </button>
        </div>
        {renderLocations()}
        <RenderError cheker={missingDate.location_id} />
        {renderFloors()}
        <RenderError cheker={missingDate.floor_number} />
        <SearchRadio
          title={translate("Booking type")}
          list={BookingType}
          name="bookingtype"
          changeHandler={changeType}
          dataset={"bookable_type"}
        />
        <RenderError cheker={missingDate.bookable_type} />
        {/* Date */}
        <div className="input">
          <label htmlFor={"date"}>{translate("Date")}</label>
          <input
            id={"date"}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={MAXDATE()}
          />
        </div>
        {/* The timeming */}
        <div className="input">
          <label>{translate("Time")}</label>
          <RadioGroup
            name={"timing"}
            selectedValue={timing}
            className="radio"
            onChange={(e) => handleTimingChange(e)}
          >
            <div className="one-radio">
              <Radio
                value={"full"}
                id={"full"}
                disabled={date <= moment(new Date()).format("YYYY-MM-DD")}
              />
              <label htmlFor={"full"}>{translate("Full day")}</label>
            </div>
            <div className="one-radio">
              <Radio
                value={"half"}
                id={"half"}
                disabled={
                  date == "" ||
                  (date <= moment(new Date()).format("YYYY-MM-DD") &&
                    moment(new Date()).format("HH:MM") >= "12:00")
                }
              />
              <label htmlFor={"half"}>{translate("half day")}</label>
            </div>
            {timing === "half" && (
              <SpecificContainer className="btns">
                <SpecificInput
                  type={"button"}
                  id="pm"
                  value="pm"
                  className={halfDay === "pm" ? "return" : "cancel"}
                  onClick={handleHalfDay}
                />
                <SpecificInput
                  type={"button"}
                  id="am"
                  value="am"
                  disabled={date <= moment(new Date()).format("YYYY-MM-DD")}
                  className={halfDay === "am" ? "return" : "cancel"}
                  onClick={handleHalfDay}
                />
              </SpecificContainer>
            )}
            <div className="one-radio">
              <Radio value={"time"} id={"time"} disabled={date == ""} />
              <label htmlFor={"time"}>{translate("Specific time")}</label>
            </div>
          </RadioGroup>
        </div>
        {timing === "time" && (
          <SpecificContainer>
            <SpecificInputWrapper>
              <SpecificLabel htmlFor="from">{translate("From")}</SpecificLabel>
              <SpecificInput
                type={"time"}
                id="from"
                onChange={handleBookingTime}
              />
            </SpecificInputWrapper>

            <SpecificInputWrapper>
              <SpecificLabel htmlFor="to">{translate("To")}</SpecificLabel>
              <SpecificInput
                type={"time"}
                id="to"
                onChange={handleBookingTime}
              />
            </SpecificInputWrapper>
          </SpecificContainer>
        )}
        {/* End timeming */}
        <RenderError cheker={missingDate.to} />
        <div className="input">
          <label>{translate("Specific requierments")}</label>
          {isSuccess && (
            <>
              {Equipments.map((equip) => {
                return (
                  <Checkbox
                    key={equip.id}
                    label={equip.name}
                    id={equip.id}
                    dataset="equipments"
                  />
                );
              })}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default Search;
