import React, { createContext, useEffect } from "react";
import { useLogin, useTranslate } from "../../Hooks";
import Input from "./Inputs/Input";
import { useNavigate } from "react-router";
import "./Login.css";
import { NotificationManager } from "react-notifications";
import { Helmet } from "react-helmet";
export const LoginContext = createContext();

const Login = () => {
  const {
    email,
    password,
    loginValue,
    loginAsync,
    isError,
    isLoading,
    isSuccess,
    resetUser,
    message,
    token,
  } = useLogin();
  const navigate = useNavigate();
  const translate = useTranslate();
  const handleSubmit = (e) => {
    e.preventDefault();
    resetUser();
    loginAsync({ email, password });
  };

  // Handle Behaviuor
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
    if (isError) {
      NotificationManager.error(translate(message));
    }
    if (isSuccess) {
      localStorage.setItem("user", token);
      navigate("/");
    }
  }, [isError, isSuccess, isLoading]);

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Imperial booking module for book meeting rooms , desks , car parks in diffrent building in UK"
        />
        <title>IBooking - Login</title>
      </Helmet>
      <div className="wrapper">
        <div className="leftSide">
          <div className="logo">
            <img
              src="https://imperialit.co.uk/images/logo-trans-color-new.PNG"
              alt=""
            />
          </div>
          <div className="gray"></div>
        </div>
        <div className="rightSide">
          <p className="login-par">{translate("Log in to your account")}</p>

          <form id="login" onSubmit={handleSubmit}>
            <LoginContext.Provider value={{ ...loginValue, isLoading }}>
              <Input
                type={"email"}
                id="email"
                autoComplete="off"
                name="email"
                label={translate("Email address")}
                placeholder="example@gmail.com"
              />
              <Input
                type={"password"}
                id="password"
                autoComplete="off"
                name="password"
                label={translate("password")}
                placeholder="********"
              />
              <Input type={"submit"} id="submit" value={translate("Log in")} />
            </LoginContext.Provider>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
