import {
  reset,
  selectSearch,
  OrganizationAsync,
  setSearch,
  equipmentsAsync,
  resultsAsync,
  modifyMissingData,
  resetEquipment
} from "../Services/Search/searchSlice";
import { getBuildings } from "../Services/Search/searchServices";
import { useDispatch, useSelector } from "react-redux";
import useLogin from "./LoginHook";
import { NotificationManager } from "react-notifications";
import useTranslate from "./Translation";
const useSearch = () => {
  const {
    isError,
    isLoading,
    isSuccess,
    organizations,
    searchObject,
    equipments,
    floorplan,
    bookables,
    message,
    missingDate,
  } = useSelector(selectSearch);
  const dispatch = useDispatch();
  const { token } = useLogin();
  const translate = useTranslate();
  const getOranizations = () => {
    dispatch(reset());
    dispatch(OrganizationAsync(token));
    dispatch(equipmentsAsync(token));
  };
  const settingSearch = (key, value) => {
    const newObj = { ...searchObject };
    if (key === "time") {
      newObj["from"] = value.from;
      newObj["to"] = value.to;
    } else {
      newObj[key] = value;
    }
    dispatch(setSearch({ newObj }));
  };
  const Buildings = async (id, modification) => {
    settingSearch(modification.key, modification.value);
    try {
      return await getBuildings({ id, token });
    } catch (error) {
      NotificationManager.error(translate("Error"));
    }
  };
  const getResults = async (data) => {
    const listOfRequiredKeys = [
      "bookable_type",
      "location_id",
      "floor_number",
      "from",
      "to",
    ];
    let error = false;
    listOfRequiredKeys.forEach((key) => {
      if (!data[key]) {
        dispatch(modifyMissingData({ key, value: true }));
        if (!error) {
          error = true;
        }
      } else {
        if (missingDate[key].error) {
          dispatch(modifyMissingData({ key, value: false }));
        }
      }
    });
    !error && dispatch(resultsAsync({ ...data, token }));

    return error
  };
  const resetEquipmentsHandler = () => {
    dispatch(resetEquipment())
  }
  return {
    getOranizations,
    isError,
    equipments,
    isLoading,
    isSuccess,
    organizations,
    settingSearch,
    Buildings,
    searchObject,
    floorplan,
    bookables,
    getResults,
    message,
    missingDate,
    resetEquipmentsHandler
  };
};

export default useSearch;
