import React from "react";
import { useTranslate } from "../../Hooks";
const Tabs = ({ changeTab, tab }) => {
  const translate = useTranslate();
  return (
    <div className="tabs">
      <ul>
        <li
          className={`fl-row-center ${tab === "map" && "active"}`}
          onClick={() => changeTab("map")}
        >
          {translate("Map view")}
        </li>
        <li
          className={`fl-row-center ${tab === "list" && "active"}`}
          onClick={() => changeTab("list")}
        >
          {translate("List view")}
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
