import React, { useState, useEffect } from "react";
import { Header, Aside, Meeting } from "../../components";
import { FaSpinner } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import {
  useBooking,
  useConfirmation,
  useLogin,
  useTranslate,
} from "../../Hooks";
import { LIMIT } from "../../Helpers/constants";
import Choose from "./Choose";
import "./Booking.css";
import { Helmet } from "react-helmet";
const Booking = () => {
  const [SearchParams] = useSearchParams();
  const [type] = useState(SearchParams.get("type"));
  const translate = useTranslate();
  const {
    confirmationRender,
    setDisplay,
    setRenderData,
    confirmationContext,
    prevRender,
  } = useConfirmation();
  const { user } = useLogin();
  const { Type } = useBooking();
  const [offset, setOffset] = useState(0);
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(true);
  const [typeState, setTypeState] = useState(type);
  const RequestBookings = async () => {
    const response = await Type(typeState, LIMIT, offset);
    if (response.Bookings.length < LIMIT) {
      setMore(false);
    }
    setMeetings([...meetings, ...response.Bookings]);
  };
  useEffect(() => {
    if (typeState || null) {
      RequestBookings().then(() => {
        setLoading(false);
      });
    }
  }, []);
  useEffect(() => {
    setTypeState(SearchParams.get("type"));
  }, [SearchParams]);
  useEffect(() => {
    setOffset(meetings.length);
  }, [meetings]);
  return (
    <confirmationContext.Provider
      value={{
        setter: setDisplay,
        setterForRender: setRenderData,
        prevRender: prevRender,
      }}
    >
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bookings page to see all your history in bookings"
        />
        <title>IBooking - Bookings</title>
      </Helmet>
      {confirmationRender()}
      <main>
        <div className="wrapper">
          <Aside />
          <div className="content">
            {typeState === null ? (
              <div className="fl-row-center">
                <Choose />
              </div>
            ) : !meetings.length ? (
              <h2>{translate("No Meetings here")}</h2>
            ) : (
              <>
                <h2>
                  {translate("BookingWelcomedMessage", {
                    fname: user.first_name,
                    lname: user.last_name,
                    typeState: translate(typeState),
                  })}
                </h2>
                <div className="bookings">
                  {meetings.map((meeting) => {
                    return (
                      <Meeting
                        key={meeting.id}
                        meeting={meeting}
                        past={typeState === "past"}
                      />
                    );
                  })}
                </div>
                <div className="fl-row-start">
                  {more && (
                    <button className="load" onClick={() => RequestBookings()}>
                      {loading ? <FaSpinner /> : "Load more"}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </confirmationContext.Provider>
  );
};

export default Booking;
