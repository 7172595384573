import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Sender,
  reset,
  selectNotifications,
  Getter,
} from "../Services/Notifications/notificationsSlice";
import {
  updateToRead,
  updateToAction,
} from "../Services/Notifications/notificationsServices";
import useLogin from "./LoginHook";
const useNotification = () => {
  const dispatch = useDispatch();
  const { notifications, isLoading } = useSelector(selectNotifications);
  const { token, user } = useLogin();

  const [UnRead, setUnread] = useState(
    notifications.filter((notify) => notify.read == 0)
  );
  useEffect(() => {
    setUnread(notifications.filter((notify) => notify.read == 0));
  }, [notifications]);

  
  const getNotifications = () => {
    dispatch(reset());
    dispatch(Getter({ user_id: user.id, token }));
  };

  const SendInvitations = (attendess, bookingId) => {
    attendess.forEach((attendee) => {
      const data = {
        type: "invitation",
        consignee_id: attendee.id,
        sender_id: user.id,
        booking_id: bookingId,
        token,
      };
      dispatch(Sender(data));
    });
  };

  const sendAction = (type, notification) => {
    const data = {
      type: type,
      consignee_id: notification.sender_id,
      sender_id: user.id,
      booking_id: notification.booking_id,
      token,
    };
    dispatch(Sender(data));
  };

  const sender = async (booking, action) => {
    console.log(user.id != booking.owner_user_id);
    if (user.id != booking.owner_user_id) {
      dispatch(
        Sender({
          type: action ? "accept" : "decline",
          booking_id: booking.id,
          consignee_id: booking.owner_user_id,
          sender_id: user.id,
          token: token,
        })
      );
    }
  };

  const readNotifications = async () => {
    if (UnRead.length) {
      await updateToRead(user.id, token);
    }
  };

  const MakeAction = async (notification_id) => {
    await updateToAction({ user_id: user.id, notification_id, token });
  };

  return {
    notifications,
    SendInvitations,
    getNotifications,
    isLoading,
    readNotifications,
    UnRead,
    MakeAction,
    sendAction,
    sender,
  };
};

export default useNotification;
