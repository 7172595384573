import { useState, createContext, useRef, useEffect } from "react";
import Confirmation from "../components/Confirmation/Confirmation";
export const confirmationContext = createContext();
const useConfirmation = () => {
  const [display, setDisplay] = useState(false);
  const [renderData, setRenderData] = useState(<></>);
  const prevRender = useRef();
  useEffect(() => {
    prevRender.current = renderData;
  }, [renderData]);
  const confirmationRender = () =>
    display && <Confirmation display={setDisplay}>{renderData}</Confirmation>;
  return {
    setDisplay,
    confirmationRender,
    setRenderData,
    confirmationContext,
    prevRender: prevRender.current,
  };
};

export default useConfirmation;
