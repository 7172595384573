import React, { useContext } from "react";
import { useBookableFloorPlan } from "../../../Hooks";
import MapView from "../../Views/Mobile/MapView/MapView";
import "../SearchResult/SearchResult.css";
import { confirmationContext } from "../../../Hooks/ConfirmationHook";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./BookableFloorplan.css";
const BookableFloorplan = ({ floorId, bookableId }) => {
  const { loading, BookableFloorplan } = useBookableFloorPlan({
    floorId,
    bookableId,
  });
  const { setterForRender, prevRender } = useContext(confirmationContext);
  return (
    <div className="parent">
      <div className="fl-row-start">
        <button
          className="mirror back"
          onClick={() => setterForRender(prevRender)}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      {loading ? (
        "Loading"
      ) : (
        <MapView
          floor={BookableFloorplan?.Floorplan.imageUrl}
          NoHeadLine
          NoActions
          bookabelOnFloor={BookableFloorplan.Bookable}
        />
      )}
    </div>
  );
};

export default BookableFloorplan;
