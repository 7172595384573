import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserBooking } from "./BookingServices";
const initialState = {
  next: [],
  past: [],
  today: [],
  attendeeIn: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
};

/*Thunks*/
export const BookingsAsync = createAsyncThunk("bookings/get", async (data) => {
  return await GetUserBooking(data);
});
/*End Of Thunks*/

export const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.past = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.next = [];
      state.today = [];
      state.attendeeIn = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BookingsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.next = action.payload.next;
        state.past = action.payload.past;
        state.today = action.payload.today;
        state.attendeeIn = action.payload.attendeeIn;
      })
      .addCase(BookingsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(BookingsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      });
  },
});

export const selectBooking = (state) => state.bookingSlice;
export default bookingSlice.reducer;
export const { reset } = bookingSlice.actions;
