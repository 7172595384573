import React from "react";
import styled from "styled-components";
import ReactLoading from 'react-loading';

const LoadingDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
`;
const Loading = () => {
  return (
    <LoadingDiv>
      <img src="https://imperialit.co.uk/images/logo-trans-color-new.PNG"/>
      <ReactLoading type={"balls"} color={"#1376b6"} height={50} width={50} />
    </LoadingDiv>
  );
};

export default Loading;
