import styled from "styled-components";

export const NotchColumn = styled.div`
width:1px;
height:10px;
border-radius:2px;
background-color:white;
`
export const NotchCircle = styled.div`
width:5px;
height:5px;
background-color:white;
margin-top:-3px;
border-radius:50%;
`
export const NotchContainer = styled.div`
position:absolute;
top : -5px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
left : ${props => props.left ? "2px" : "unset"};
right : ${props => props.right ? "2px" : "unset"};
`