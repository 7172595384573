//@ component that helps the user to understand what is going on
import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useTranslate } from "../Hooks";
const Flash = ({ children }) => {
  const translate = useTranslate();
  useEffect(() => {
    if (localStorage.getItem("expiration")) {
      NotificationManager.error(
        translate("Your session is expired please login again")
      );
      localStorage.removeItem("expiration");
    }
    if (localStorage.getItem("resetExpire")) {
      NotificationManager.error(translate("reset password token expired!"));
      localStorage.removeItem("resetExpire");
    }
    if (localStorage.getItem("resetSuccess")) {
      NotificationManager.success(translate("Password changed successfully "));
      localStorage.removeItem("resetSuccess");
    }
  }, []);
  return <>{children}</>;
};

export default Flash;
