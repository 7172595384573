import React from "react";
import styled from "styled-components";

const changePosition = (stringInPerecent, level) => {
  const splittedString = stringInPerecent.split("%");
  const Percentegae = Number(splittedString[0]) - level;
  return `${Percentegae}%`;
};
const desktop = window.screen.availWidth > 1200;
const bookableDimension = () => {
  if (desktop) return 15;
  return 5;
};
const BookableCircle = styled.div`
  position: absolute;
  width: ${(props) => props.div_width};
  height: ${(props) => props.div_height};
  top: ${(props) => changePosition(props.div_top, desktop ? 0 : 2)};
  left: ${(props) => changePosition(props.div_left, desktop ? 0 : 1)};
  border-radius: 50%;
  cursor: pointer;
`;

const OneBookable = ({ bookable }) => {
  return (
    <BookableCircle
      div_width={`${bookableDimension()}px`}
      div_height={`${bookableDimension()}px`}
      div_top={bookable.divTop}
      div_left={bookable.divLeft}
      className={desktop ? "selecteddot" : "selecteddot selecteddotmobile"}
    />
  );
};

export default OneBookable;
