import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendNotifications, getNotifications } from "./notificationsServices";
const initialState = {
  notifications: [],
  isLoading: false,
};

/*THUNKS*/
export const Sender = createAsyncThunk("Sender/Notifications", async (data) => {
  return await sendNotifications(data);
});

export const Getter = createAsyncThunk("Getter/Notifications", async (data) => {
  return await getNotifications(data);
});

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Getter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Getter.fulfilled, (state, action) => {
        state.notifications = [...action.payload.notifications];
        state.isLoading = false;
      });
  },
});

export default notificationsSlice.reducer;
export const { reset } = notificationsSlice.actions;
export const selectNotifications = (state) => state.notificationsSlice;
