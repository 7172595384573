import React, { useEffect, lazy, Suspense } from "react";
import "./NewBooking.css";
import { Header } from "../../components";
import { useConfirmation } from "../../Hooks";
import { Search , Loading} from "../../components";
import {Helmet} from "react-helmet"
const Desktop = lazy(() => import("./Desktop"));
const NewBooking = () => {
  const { confirmationRender, setDisplay, setRenderData, confirmationContext } =
    useConfirmation();

  useEffect(() => {
    setDisplay(true);
    setRenderData(<Search display={setDisplay} setRender={setRenderData} />);
  }, []);
  return (
    <confirmationContext.Provider
      value={{ setter: setDisplay, setterForRender: setRenderData }}
    >
      <Header />
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Craete new booking with IBooking module , see detailed floorplan for UK building , invite your friends , filter your results , choose your equipments"
          />
          <title>IBooking - Search</title>
        </Helmet>
        {window.screen.availWidth > 992 ? (
          <Suspense fallback={<Loading />}>
            <Desktop />
          </Suspense>
        ) : (
          confirmationRender()
        )}
      </main>
    </confirmationContext.Provider>
  );
};

export default NewBooking;
