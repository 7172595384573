import React from "react";
import { useTranslate } from "../../Hooks";
import "./Attendes.css";
const Attendes = ({ list }) => {
  const translate = useTranslate()
  return (
    <div className="attendes-container">
      <p>{translate("Attendes")} ({list.length})</p>
      {list.map((member) => {
        return (
          <div className="user fl-row-start" key={member.id}>
            <span>
              {member.name.split(" ")[0][0]}
              {member.name.split(" ")[1][0]}
            </span>
            <span>{member.name}</span>
            <span>({member.type})</span>
          </div>
        );
      })}
    </div>
  );
};

export default Attendes;
