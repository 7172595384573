import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTeam, createBookingRequest } from "./createBookingServices";
const initialState = {
  selectedDot: -1,
  selectedDotInformation: {},
  team: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
};

/* THUNKS */

export const GetTeam = createAsyncThunk(
  "createbooking/getteam",
  async (data) => {
    return await getTeam(data);
  }
);
export const boookingCreation = createAsyncThunk(
  "createBooking/bookingCreation",
  async (data) => {
    return await createBookingRequest(data);
  }
);
/* END THUNKS */

const createBooking = createSlice({
  name: "createBookingSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.selectedDot = -1;
      state.selectedDotInformation = {};
    },
    resetCreateRequest: (state) => {
      state.isError = false;
      state.isSuccess = false;
    },
    setSelectedDot: (state, action) => {
      state.selectedDot = action.payload.id;
    },
    setInformation: (state, action) => {
      state.selectedDotInformation = { ...action.payload.bookable };
    },
    setAttendees: (state, action) => {
      if (state.selectedDotInformation["attendes"]) {
        state.selectedDotInformation["attendes"] = [
          ...state.selectedDotInformation["attendes"],
          action.payload.attendee,
        ];
      } else {
        state.selectedDotInformation["attendes"] = [action.payload.attendee];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTeam.fulfilled, (state, action) => {
        state.team = action.payload.team;
      })
      .addCase(GetTeam.rejected, (state) => {
        state.isError = true;
      })
      .addCase(boookingCreation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(boookingCreation.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(boookingCreation.fulfilled, (state,action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      });
  },
});

export const selectCreateBooking = (state) => state.createBookingSlice;
export default createBooking.reducer;
export const {
  reset,
  setSelectedDot,
  setInformation,
  setAttendees,
  resetCreateRequest,
} = createBooking.actions;
