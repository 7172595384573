import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login, Dashboard, NewBooking, Booking, Forgot, Reset } from "./pages";
import { PrivateRoute, Flash } from "./components";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { useEffect, useContext } from "react";
import { SocketContext } from "./Services/Socket";
import { useLogin } from "./Hooks";
function App() {
  const { socket } = useContext(SocketContext);
  const { user } = useLogin();
  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("user_room", `${user.id}${user.first_name}${user.last_name}`);
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <Flash>
              <Login />
            </Flash>
          }
        />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/reset" element={<Reset />} />
        <Route
          path="/new"
          element={
            <PrivateRoute>
              <NewBooking />
            </PrivateRoute>
          }
        />
        <Route
          path="/bookings"
          element={
            <PrivateRoute>
              <Booking />
            </PrivateRoute>
          }
        />
      </Routes>
      <NotificationContainer />
    </Router>
  );
}

export default App;
