import React from "react";
import "./NotificationBoss.css";
import BookingInvitation from "./Components/BookingInvitation";
import InvitationAction from "./Components/InvitationAction";
import { useNotification } from "../../../../Hooks";
const NotificationBoss = () => {
  const { notifications, isLoading } = useNotification();
  const notificationsType = {
    invitation: (notification) => {
      return (
        <BookingInvitation notification={notification} key={notification.id} />
      );
    },
    decline: (notification) => {
      return (
        <InvitationAction
          notification={notification}
          key={notification.id}
          decline
        />
      );
    },
    accept: (notification) => {
      return (
        <InvitationAction notification={notification} key={notification.id} />
      );
    },
  };
  if (isLoading) {
    return "Loading";
  }
  return (
    <div className="notificationBoss">
      {notifications.map((notify) => {
        return notificationsType[notify["type"]](notify);
      })}
    </div>
  );
};

export default NotificationBoss;
