import React from "react";

const RenderError = ({ cheker }) => {
  return (
    <div
      className="error"
      style={{ display: `${cheker.error ? "block" : "none"}` }}
    >
      <small>{cheker.message}</small>
    </div>
  );
};

export default RenderError;
