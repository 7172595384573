import axios from "axios";
import { USER_API } from "../../Helpers/constants";

const Login = async (data) => {
  try {
    const response = await axios.post(USER_API + `/login`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export const AttendAction = async (user_id, booking_id, action, token) => {
  try {
    const response = await axios.post(
      USER_API + `/attendBooking`,
      {
        user_id,
        booking_id,
        action,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.msg || error.message);
  }
};

export default Login;
