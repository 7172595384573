import {
  reset,
  selectBooking,
  BookingsAsync,
} from "../Services/Booking/Bookingslice";
import { AttendAction } from "../Services/user/userService";
import {
  GetBookingAttendess,
  DeleteBooking,
  UpdateBooking,
  BookingsType,
} from "../Services/Booking/BookingServices";
import { useDispatch, useSelector } from "react-redux";
import useLogin from "./LoginHook";
import axios from "axios";
import { Booking_API } from "../Helpers/constants";
const useBooking = (booking_id = 0) => {
  const { next, past, isError, isLoading, isSuccess, today, attendeeIn } =
    useSelector(selectBooking);
  const dispatch = useDispatch();
  const { user, token } = useLogin();
  const getBookings = () => {
    dispatch(reset());
    dispatch(BookingsAsync({ id: user.id, token }));
  };
  let attendeeObj = {};
  if (booking_id) {
    const attendee = attendeeIn.filter((att) => att.bookingId === booking_id);
    attendeeObj = { ...attendee[0] };
  }
  const Delete = async () => {
    if (!booking_id) {
      throw new Error("No booking");
    }
    await DeleteBooking(booking_id, token);
    getBookings();
  };

  const Update = async (data) => {
    if (!booking_id) {
      throw new Error("No booking");
    }
    await UpdateBooking(data, token);
    getBookings();
  };
  const Type = (type, limit, offset) => {
    return BookingsType(user.id, type, limit, offset, token);
  };
  const check_availabilty = async (id, from, to ) => {
    try {
      const response = await axios.post(
        Booking_API + "/check_availabilty",
        { id, from, to},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  return {
    next,
    past,
    isError,
    isLoading,
    isSuccess,
    getBookings,
    today,
    attendeeIn,
    attendeeObj,
    AttendAction,
    GetBookingAttendess,
    Delete,
    Update,
    Type,
    check_availabilty,
  };
};

export default useBooking;
