import {useEffect} from "react"

const useOutside = (ref , fn , exceptClass = "") => {
    useEffect(() => {
      function handleClickOutside(event) {
        const NoClass = !event.target.classList.contains(exceptClass);
        if (ref.current && !ref.current.contains(event.target) && NoClass) {
          fn(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  export default useOutside