import { useEffect } from "react";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { useLogin } from "../Hooks";
const PrivateRoute = ({ children }) => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const { resetUser } = useLogin();
  const verifyToken = () => {
    if (user) {
      const exp = jwtDecode(user).exp * 1000;
      const date = new Date();
      const isExpired = exp < date.getTime();
      if (isExpired) {
        try {
          localStorage.removeItem("user");
          localStorage.setItem("expiration", true);
        } catch (error) {}
      }
      return isExpired;
    }
    return true;
  };
  useEffect(() => {
    if (!user || verifyToken()) {
      resetUser();
      navigate("/login");
    }
  }, []);
  return <>{user && children}</>;
};

export default PrivateRoute;
