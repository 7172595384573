import { useState } from "react";
import { useDispatch , useSelector } from "react-redux";
import { selectUser ,LoginAsync , reset} from "../Services/user/userSlice";
const useLogin = () => {
  // Handling Login Form
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [errors , setErrors] = useState({email : false,password : false});

  const loginValue = {
    setErr : (key , value) => {
      setErrors({...errors , [key] : value})
    },
    email : {
      value : email,
      setter : (value) => setEmail(value)
    },
    password : {
      value : password,
      setter : (value) => setPassword(value)
    },
    valid : () => errors.email && errors.password,
  }

  // API integeration
  const dispatch = useDispatch();

  const {isLoading , isError , user , message , isSuccess , token} = useSelector(selectUser);

  const loginAsync = (data) => dispatch(LoginAsync(data));

  const resetUser = () => dispatch(reset());



  
  return {
    email,
    password,
    loginValue,
    loginAsync,
    isLoading,
    isError,
    user,
    resetUser,
    message,
    isSuccess,
    token
    };
};
export default useLogin
